import React from 'react'
import SEO from '../components/seo/seo'
import Bio from '../components/bio/bio'
import { IconContext } from "react-icons";
import { FaFacebookSquare, FaLinkedin  } from 'react-icons/fa'

const AboutPage = () => (
  <>
    <IconContext.Provider value={{ color: "var(--primary-color)", style: { verticalAlign: 'middle', fontSize: '3rem' }, className: "social-icons"}}>
    <SEO
      title="About"
      description="Pleasant Street Title & Closing is a full service title company offering services for brokered and non brokered real estate transactions. Helping buyers, sellers and lenders including closings, title searches, title insurance, mortgage discharges and deeds by experienced real estate professionals overseen by Attorney James G. Feleen, Esq."
      keywords={['pleasant street', 'pleasant street title & closing', 'title', 'title insurance', 'title search', 'closing', 'closings', 'buyer', 'buyers', 'seller', 'sellers', 'lender', 'lenders', 'real estate', 'realtor', 'broker', 'mortgage', 'real estate lawyer', 'real estate attorney', 'law', 'lawyer', 'law office', 'attorney', 'James Feleen', 'Jim Feleen', 'Claremont', 'NH', 'New Hampshire', 'Claremont NH', 'sullivan county', 'grafton county', 'cheshire county', 'merrimack county', 'Acworth', 'Charlestown', 'Cornish', 'Croydon', 'Goshen', 'Grantham', 'Langdon', 'Lempster', 'Newport', 'Plainfield', 'Springfield', 'Sunapee', 'Unity', 'Washington']} />
    <Bio />
    <div className="clear"></div>
    <div className="body-wrapper">
    <div className="experience">
      <h2>Education</h2>
        <div className="eds">
          <div className="ed">
            <h4>Bethel College, St. Paul MN</h4>
            <h5>B.A. 1980</h5>
          </div>
          <div className="ed">
            <h4>William Mitchell College of Law, St. Paul MN</h4>
            <h5>J.D. 1984</h5>
          </div>
        </div>
      </div>
    <div className="experience">
      <div className="memberships">
        <div className="member">
          <h2>Member</h2>
          <ul>
            <li>New Hampshire and Sullivan County Bar Associations</li>
            <li>Greater Claremont Chamber of Commerce</li>
            <li>Greater Claremont Board of Realtors</li>
            <li>Parks and Recreation Commission</li>
            <li>Board of Directors, Singing Hills Christian Fellowship</li>
            <li>Grace River Church</li>
            <li>NH Rail Trail Coalition</li>
          </ul>
        </div>
        <div className="former">
          <h2>Former Member</h2>
          <ul>
            <li>NH Bar Association Board of Governors</li>
            <li>Claremont School Board</li>
            <li>Goodwin Community Center Commission</li>
          </ul>
        </div>
      </div>
      </div>
      <div className="experience last">
        <h2>Connect On Social Media</h2>
        <div>
          <a href="https://www.facebook.com/Law-Office-of-James-G-Feleen-pllc-163317153825643/" target="_blank" rel="noopener noreferrer"><FaFacebookSquare /></a> <a href="https://www.linkedin.com/in/james-feleen-37782468/" target="_blank" rel="noopener noreferrer">< FaLinkedin /></a>
        </div>
      </div>

    </div>


    </IconContext.Provider>
  </>
)

export default AboutPage
